import { FormattedMessage } from 'react-intl';
import { ProjectConfig } from '~/config/ProjectConfig';

export default function FooterInfomation() {
  return (
    <div className='footer-info'>
      <div className='mb-4 leading-8'>
        <p className='pb-4 text-center sm:text-left'>
          <FormattedMessage
            id='app.common.company_intro1'
            defaultMessage={`${ProjectConfig.companyName} is a company which committed to caring and sharing ‘Healthy’ with every single one of you.`}
          />
        </p>

        <p className='pb-4 text-center sm:text-left'>
          <FormattedMessage
            id='app.common.company_intro2'
            defaultMessage={`${ProjectConfig.companyName} strived to be YOUR trusted health brand. 😉`}
          />
        </p>

        <p className='pb-4 text-center font-bold'>
          <FormattedMessage id='app.common.company_intro4' defaultMessage='’EAT GOOD. LOOK GOOD. FEEL GOOD.’' />
        </p>
      </div>
    </div>
  );
}
