import { AxiosResponse } from 'axios';
import { PaginateResponseT } from '~/@customTypes/generic';
import { ProductT } from '~/@customTypes/product.type';
import { axiosInstanceWithoutProxy } from '~/v2/utils/service';

export const fetchProducts = async (filter: {
  limit: number;
  locale?: string | undefined;
  name?: string | undefined;
}): Promise<PaginateResponseT<ProductT[]> | null> => {
  try {
    const result: AxiosResponse<PaginateResponseT<ProductT[]>> = await axiosInstanceWithoutProxy.get('/products', {
      params: filter,
    });

    if (result.status === 200 && result.data) {
      return new Promise((resolve) => resolve(result.data));
    }
  } catch (error) {
    console.log(error);
  }

  return new Promise((resolve) => resolve(null));
};
