import { useContext } from 'react';
import { ChatIcon, ChevronRightIcon } from '@heroicons/react/outline';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';

import { facebook, instagram, youtube } from '~/data/social-media';
import FooterInfomation from './elements/FooterInfomation';
import { AppContext, AppContextT } from '~/v2/context/AppProvider';
import { About, Helps } from '~/v2/utils/footer';
import Locales from '~/v2/components/header/Locales';
import { useRouter } from 'next/router';
import { ProjectConfig } from '~/config/ProjectConfig';

function Footer() {
  const { pathname } = useRouter();
  const { groups } = useContext<AppContextT>(AppContext);

  const isCheckoutPage = pathname === '/checkout';

  return (
    <div className='relative w-full'>
      <div style={{ position: 'fixed', bottom: isCheckoutPage ? 70 : 10, left: 5, zIndex: 5 }}>
        <a
          href={`${ProjectConfig.companyWhatsapp}?text=Hello,%20I'm%20looking%20for`}
          target='_blank'
          style={{
            display: 'inline-block',
            height: 50,
            width: 50,
            backgroundColor: '#7C6C53',
            borderRadius: 25,
            padding: 11,
            transformOrigin: '100% 100%',
            margin: '0 5px',
            boxSizing: 'border-box',
          }}
        >
          <ChatIcon className='text-white' />
        </a>
      </div>

      <div className='max-w-7xl mx-auto px-4 sm:px-0'>
        <div className='text-center sm:text-left'>
          <Link href='/'>
            <a>
              <img
                src='/assets/images/logo-horizontal.png'
                alt={ProjectConfig.companyName}
                style={{ objectFit: 'contain' }}
                className='my-4 aspecct-square w-72 object-contain'
                loading='lazy'
              />
            </a>
          </Link>
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-3 gap-4'>
          <div>
            <FooterInfomation />
            <div className='w-1/3 sm:w-1/5 mx-auto'>
              <div className='flex flex-row justify-between'>
                <div>
                  <a href={facebook} target='_blank'>
                    <i className='fab fa-facebook-f text-lg'></i>
                  </a>
                </div>
                <div>
                  <a href={instagram} target='_blank'>
                    <i className='fab fa-instagram text-lg'></i>
                  </a>
                </div>
                <div>
                  <a href={youtube} target='_blank'>
                    <i className='fab fa-youtube text-lg'></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className='mb-4 sm:mb-0 sm:col-span-2'>
            <div className='grid grid-cols-1 gap-4 sm:grid-cols-3'>
              <div className='mb-4'>
                <p className='text-xl font-bold pb-2'>
                  <FormattedMessage id='app.menu.products' defaultMessage='Products' />
                </p>
                <ul>
                  {groups.map((group) => (
                    <li key={group.id} className='text-lg'>
                      <Link href={`/packages/${group.id}`}>
                        <a className='flex flex-row items-center py-1 text-gray-400'>
                          {group.name}
                          <ChevronRightIcon className='h-5 w-5 ml-1' />
                        </a>
                      </Link>
                    </li>
                  ))}
                  {process.env.NEXT_PUBLIC_ENABLE_MEMBERSHIP === 'true' && (
                    <li key={-1} className='text-lg'>
                      <Link href='/reward'>
                        <a className='flex flex-row items-center py-1 text-gray-400'>
                          <FormattedMessage id='app.common.b_coin_reward' defaultMessage='B-COIN REDEMPTION' />
                          <ChevronRightIcon className='h-5 w-5 ml-1' />
                        </a>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
              <div className='mb-4'>
                <p className='text-xl font-bold pb-2'>
                  <FormattedMessage id='app.common.who_are_we' defaultMessage='Who are we' />
                </p>
                <ul>
                  {About.map((link) => (
                    <li key={link.id} className='text-lg flex flex-row items-center py-1 text-gray-400'>
                      <Link href={link.href}>
                        <a>
                          <FormattedMessage id={link.id} defaultMessage={link.title} />
                        </a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className='mb-4'>
                <p className='text-xl font-bold pb-2'>
                  <FormattedMessage id='app.common.disclaimer' defaultMessage='Disclaimer' />
                </p>
                <ul>
                  {Helps.map((link) => (
                    <li key={link.id} className='text-lg flex flex-row items-center py-1 text-gray-400'>
                      <Link href={link.href}>
                        <a>
                          <FormattedMessage id={link.id} defaultMessage={link.title} />
                        </a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='relative bg-main-300 pt-8 pb-8 sm:pt-2 sm:pb-2'>
        <div className='fixed right-0' style={{ bottom: isCheckoutPage ? 60 : 0 }}>
          <Locales />
        </div>

        <div className='w-8/12 mx-auto'>
          <p className='text-center font-bold'>Copyright © 2022 {ProjectConfig.companyName}</p>
          <p className='text-center font-bold'>All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
}
export default Footer;
