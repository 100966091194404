import Link from 'next/link';
import { useContext } from 'react';

import Container from '~/components/other/Container';
import { ProjectConfig } from '~/config/ProjectConfig';

import FunctionItems from '~/v2/components/header/FunctionItems';
import Navigator from '~/v2/components/header/Navigator';
import { LocaleContext, LocaleContextT } from '~/v2/context/LocaleProvider';
import { SearchContext, SearchContextT } from '~/v2/context/SearchProvider';
import { Autocomplete } from '~/v2/modules/products/Autocomplete';
import PromotionBar from '~/v2/modules/settings/PromotionBar/PromotionBar.index';
import MobileMenuOpener from '../menu/MobileMenuOpener';

export default function MenuOne() {
  const { locale } = useContext<LocaleContextT>(LocaleContext);
  const { displaySearch } = useContext<SearchContextT>(SearchContext);

  return (
    <div>
      <PromotionBar />

      <Container>
        <div className='flex items-center justify-between'>
          <MobileMenuOpener />
          <Link href='/'>
            <a className='menu-logo'>
              <img
                src='/assets/images/logo-horizontal.png'
                alt={ProjectConfig.companyName}
                className='w-40 sm:w-72 h-20 sm:h-36 object-contain'
                loading='lazy'
              />
            </a>
          </Link>

          <div className='sm:w-full'>
            <FunctionItems />
            <Navigator />
          </div>
        </div>
      </Container>

      {displaySearch && <Autocomplete placeholder='Search Products' locale={locale} />}
    </div>
  );
}
