import Link from 'next/link';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { UserIcon, ShoppingCartIcon, SearchIcon } from '@heroicons/react/outline';

import { AuthContext } from '~/v2/context/AuthProvider';
import { CartContext } from '~/v2/context/CartProvider';
import Locales from './Locales';
import { Autocomplete } from '~/v2/modules/products/Autocomplete';
import { LocaleContext, LocaleContextT } from '~/v2/context/LocaleProvider';
import { SearchContext, SearchContextT } from '~/v2/context/SearchProvider';

function FunctionItems() {
  const authCtx = useContext(AuthContext);
  const cartCtx = useContext(CartContext);
  const { locale } = useContext<LocaleContextT>(LocaleContext);
  const { setDisplaySearch } = useContext<SearchContextT>(SearchContext);

  const { formatMessage } = useIntl();

  return (
    <div className='flex flex-row justify-end items-center my-2 space-x-3'>
      <div className='hidden sm:block'>
        <Autocomplete
          placeholder={formatMessage({ id: 'app.search_bar.title', defaultMessage: 'Search Products' })}
          locale={locale}
        />
      </div>

      <div className='hidden sm:block'>
        <Locales />
      </div>

      <div className='block sm:hidden'>
        <SearchIcon
          className='h-6 w-6 cursor-pointer'
          onClick={() => {
            setDisplaySearch((prev) => !prev);
          }}
        />
      </div>

      {!authCtx.isLoggedIn && (
        <UserIcon
          className='h-6 w-6 cursor-pointer'
          onClick={() => {
            authCtx.openLoginModal();
          }}
        />
      )}
      {authCtx.isLoggedIn && (
        <Link href='/my-account'>
          <a style={{ color: '#000' }}>
            <UserIcon className='h-6 w-6 cursor-pointer' />
          </a>
        </Link>
      )}

      <Link href='/cart'>
        <a className='flex flex-row'>
          <ShoppingCartIcon className='h-6 w-6 cursor-pointer' />
          <span>{cartCtx.cart?.formated_base_sub_total || '0.00'}</span>
        </a>
      </Link>
    </div>
  );
}

export default FunctionItems;
