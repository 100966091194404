import { useState } from 'react';
import { Drawer } from 'antd';
import { FormattedMessage } from 'react-intl';

import MobileNavigator from '~/v2/components/navigators/MobileNavigator';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

function MobileMenuOpener() {
  const [visible, setVisible] = useState(false);

  const onShowDrawer = () => {
    setVisible(true);
  };

  const onCloseDrawer = () => {
    setVisible(false);
  };

  return (
    <>
      <a onClick={onShowDrawer} className='menu-mobile-opener block sm:hidden' href='#'>
        <MenuIcon className='h-8 w-8' />
      </a>
      <Drawer
        title={
          <p className='text-white'>
            <FormattedMessage id='app.common.close' defaultMessage='Close' />
          </p>
        }
        closable={true}
        onClose={onCloseDrawer}
        visible={visible}
        placement='left'
        width={320}
        drawerStyle={{ backgroundColor: '#433C33' }}
        headerStyle={{ backgroundColor: '#433C33', borderColor: '#433C33' }}
        closeIcon={<XIcon className='h-6 w-6 text-white' />}
      >
        <MobileNavigator onCloseDrawer={onCloseDrawer} />
      </Drawer>
    </>
  );
}

export default MobileMenuOpener;
