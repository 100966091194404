/**
 *
 * @description PromotionBar.index.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 21 March 2022
 *
 */

import { useContext, useEffect, useState } from 'react';
import { LocaleContext, LocaleContextT } from '~/v2/context/LocaleProvider';
import Settings from '../settings.service';

export default function PromotionBar() {
  const [content, setContent] = useState('');
  const { locale } = useContext<LocaleContextT>(LocaleContext);

  useEffect(() => {
    (async () => {
      const settings = new Settings();

      const result = await settings.get('PromotionBar', { locale });

      if (result && result.data) {
        setContent(result.data.short_content);
      }
    })();
  }, [locale]);

  if (!content) {
    return null;
  }

  return (
    <div className='py-2 px-4 text-center' style={{ backgroundColor: '#FEB256' }}>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
}
