import { AxiosResponse } from 'axios';
import { ResponseT } from '~/@customTypes/generic';
import { axiosInstanceWithoutProxy } from '~/v2/utils/service';
import { SettingsT } from './settings.type';

class Settings {
  get = async (purpose: string, params?: { locale: string } | undefined): Promise<ResponseT<SettingsT> | null> => {
    try {
      const result: AxiosResponse<ResponseT<SettingsT>> = await axiosInstanceWithoutProxy.get(
        'commerce/v1/settings/' + purpose,
        {
          params,
        }
      );

      if (result && result.status === 200) {
        return new Promise((resolve) => resolve(result.data));
      }
    } catch (error) {
      console.log(error);
    }

    return new Promise((resolve) => resolve(null));
  };
}

export default Settings;
