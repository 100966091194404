import { Fragment, useContext } from 'react';
import Link from 'next/link';

import { AppContext, AppContextT } from '~/v2/context/AppProvider';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { Popover, Transition } from '@headlessui/react';
import { classNames } from '~/v2/utils/general';
import { FormattedMessage } from 'react-intl';

function Navigator() {
  const { groups } = useContext<AppContextT>(AppContext);

  return (
    <div className='relative'>
      <div className='hidden sm:block'>
        <Popover className='z-0 relative'>
          {({ open, close }) => (
            <>
              <div className='relative z-10 bg-white'>
                <div className='max-w-7xl mx-auto flex px-4 py-4 sm:px-6 lg:px-8 space-x-4'>
                  {groups
                    .filter((item) => item.sort <= 5)
                    .map((group, index) => (
                      <Link key={index} href={`/packages/${group.id}`}>
                        <a
                          className='flex flex-row items-center text-gray-400 group-hover:text-gray-500'
                          onClick={() => {
                            close();
                          }}
                        >
                          {group.name} <ChevronDownIcon className='h-4 w-4 ml-2' />
                        </a>
                      </Link>
                    ))}

                  {(groups.length > 5 || process.env.NEXT_PUBLIC_ENABLE_MEMBERSHIP === 'true') && (
                    <Popover.Button
                      className={classNames(
                        open ? 'text-gray-900' : 'text-gray-400',
                        'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none'
                      )}
                    >
                      <span>
                        <FormattedMessage id='app.common.more' defaultMessage='More' />
                      </span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? 'text-gray-600' : 'text-gray-400',
                          'ml-2 h-4 w-4 group-hover:text-gray-500'
                        )}
                        aria-hidden='true'
                      />
                    </Popover.Button>
                  )}
                </div>
              </div>

              <Transition
                as={Fragment}
                enter='transition ease-out duration-200'
                enterFrom='opacity-0 -translate-y-1'
                enterTo='opacity-100 translate-y-0'
                leave='transition ease-in duration-150'
                leaveFrom='opacity-100 translate-y-0'
                leaveTo='opacity-0 -translate-y-1'
              >
                <Popover.Panel className='absolute z-10 inset-x-0 transform shadow-lg'>
                  <div className='bg-white'>
                    <div className='max-w-7xl mx-auto flex flex-row px-4 py-6 sm:gap-8 sm:px-6 sm:py-8'>
                      {groups
                        .filter((item) => item.sort > 5)
                        .map((group, index) => (
                          <Link key={index} href={`/packages/${group.id}`}>
                            <a
                              className='flex flex-row items-center text-gray-400 group-hover:text-gray-500'
                              onClick={() => {
                                close();
                              }}
                            >
                              {group.name} <ChevronDownIcon className='h-4 w-4 ml-2' />
                            </a>
                          </Link>
                        ))}

                      {process.env.NEXT_PUBLIC_ENABLE_MEMBERSHIP === 'true' && (
                        <Link key={-1} href='/reward'>
                          <a
                            className='flex flex-row items-center text-gray-400 group-hover:text-gray-500'
                            onClick={() => {
                              close();
                            }}
                          >
                            <FormattedMessage id='app.common.b_coin_reward' defaultMessage='B Coins Reward' />
                            <ChevronDownIcon className='h-4 w-4 ml-2' />
                          </a>
                        </Link>
                      )}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </div>
  );
}

export default Navigator;
