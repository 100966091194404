import Link from 'next/link';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { ChevronRightIcon } from '@heroicons/react/outline';

import { facebook, instagram, youtube } from '~/data/social-media';
import { AppContext, AppContextT } from '~/v2/context/AppProvider';
import { About, Helps } from '~/v2/utils/footer';
import { AuthContext, AuthContextT } from '~/v2/context/AuthProvider';
import { useRouter } from 'next/router';
import { classNames } from '~/v2/utils/general';

type Props = {
  onCloseDrawer?: (() => void) | undefined;
};
function MobileNavigator({ onCloseDrawer }: Props) {
  const authContext = useContext<Partial<AuthContextT>>(AuthContext);
  const { groups } = useContext<AppContextT>(AppContext);

  const { asPath } = useRouter();

  return (
    <div className='menu-mobile'>
      <div>
        <p className='text-xl font-bold pb-2 text-white underline'>
          <FormattedMessage id='app.menu.products' defaultMessage='Products' />
        </p>

        <div className='divide-y-2 mb-8'>
          {groups.map((group, index) => (
            <Link key={index} href={`/packages/${group.id}`}>
              <a
                className={classNames(
                  asPath === `/packages/${group.id}` ? 'text-main' : 'text-gray-300',
                  'flex flex-row justify-between items-center group-hover:text-gray-500 py-4'
                )}
                onClick={onCloseDrawer}
              >
                {group.name} <ChevronRightIcon className='h-4 w-4 ml-2' />
              </a>
            </Link>
          ))}

          {process.env.NEXT_PUBLIC_ENABLE_MEMBERSHIP === 'true' && (
            <Link key={-1} href='/reward'>
              <a
                className={classNames(
                  asPath === '/reward' ? 'text-main' : 'text-gray-300',
                  'flex flex-row justify-between items-center group-hover:text-gray-500 py-4'
                )}
                onClick={onCloseDrawer}
              >
                <FormattedMessage id='app.common.b_coin_reward' defaultMessage='B-COIN REDEMPTION' />
                <ChevronRightIcon className='h-4 w-4 ml-2' />
              </a>
            </Link>
          )}
        </div>

        <div>
          <p className='text-xl font-bold pb-2 text-white underline'>
            <FormattedMessage id='app.common.who_are_we' defaultMessage='Who are we' />
          </p>

          <ul>
            {/* {About.map((link) => (
              <li
                key={link.id}
                className={classNames(
                  asPath === link.href ? 'text-main' : 'text-gray-300',
                  'text-lg flex flex-row items-center py-2 text-gray-300'
                )}
              >
                <Link href={link.href}>
                  <a onClick={onCloseDrawer}>
                    <FormattedMessage id={link.id} defaultMessage={link.title} />
                  </a>
                </Link>
              </li>
            ))}
            {Helps.map((link) => (
              <li
                key={link.id}
                className={classNames(
                  asPath === link.href ? 'text-main' : 'text-gray-300',
                  'text-lg flex flex-row items-center py-2 text-gray-300'
                )}
              >
                <Link href={link.href}>
                  <a onClick={onCloseDrawer}>
                    <FormattedMessage id={link.id} defaultMessage={link.title} />
                  </a>
                </Link>
              </li>
            ))} */}

            {!authContext.isLoggedIn && (
              <li key='login'>
                <Link href='/login'>
                  <a
                    className={classNames(
                      asPath === '/login' ? 'text-main' : 'text-gray-300',
                      'text-lg flex flex-row items-center py-2'
                    )}
                    onClick={onCloseDrawer}
                  >
                    <FormattedMessage id='app.common.login_register' defaultMessage='Login / Register' />
                  </a>
                </Link>
              </li>
            )}

            {authContext.isLoggedIn && (
              <>
                <li key='my-account'>
                  <Link href='/my-account'>
                    <a
                      className={classNames(
                        asPath === '/login' ? 'text-main' : 'text-gray-300',
                        'text-lg flex flex-row items-center py-2'
                      )}
                      onClick={onCloseDrawer}
                    >
                      <FormattedMessage id='app.common.my_account' defaultMessage='My Account' />
                    </a>
                  </Link>
                </li>

                <li key='logout'>
                  <a
                    className='text-lg flex flex-row items-center py-2 text-gray-300'
                    href='#'
                    onClick={(event) => {
                      event.preventDefault();
                      authContext.logout?.();
                    }}
                  >
                    <FormattedMessage id='app.common.logout' defaultMessage='Logout' />
                  </a>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>

      <div className='border-t border-t-white mt-4 pt-4'>
        <div className='w-6/12 mx-auto'>
          <div className='flex flex-row justify-between'>
            <div>
              <a href={facebook} target='_blank'>
                <i className='fab fa-facebook-f text-lg text-white'></i>
              </a>
            </div>
            <div>
              <a href={instagram} target='_blank'>
                <i className='fab fa-instagram text-lg text-white'></i>
              </a>
            </div>
            <div>
              <a href={youtube} target='_blank'>
                <i className='fab fa-youtube text-lg text-white'></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileNavigator;
