/**
 *
 * @description Locales.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 20 February 2022
 *
 */

import { useRouter } from 'next/router';
import { useContext } from 'react';
import { LocaleContext, LocaleContextT } from '~/v2/context/LocaleProvider';

export default function Locales() {
  const router = useRouter();
  const localeCtx = useContext<LocaleContextT>(LocaleContext);

  return (
    <select
      id='locale'
      name='locale'
      className='mt-1 sm:block w-36 px-4 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
      value={localeCtx.locale}
      onChange={(e) => {
        localeCtx.setLocale(e.target.value);

        router.push(router.pathname, router.asPath, {
          locale: e.target.value,
        });
      }}
    >
      {localeCtx.locales?.map(
        (locale) =>
          locale && (
            <option key={locale.code} value={locale.code}>
              {locale.name}
            </option>
          )
      )}
    </select>
  );
}
