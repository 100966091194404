export const About = [
  { title: 'About us', href: '/about-us', id: 'app.common.about_us' },
  { title: 'Contact', href: '/contact-us', id: 'app.common.contact_us' },
  { title: 'Blogs', href: '/blogs', id: 'app.common.blogs' },
];

export const Helps = [
  { title: 'How To Order', href: '/how-to-order', id: 'app.common.how_to_order' },
  { title: 'Payment Methods', href: '/payment-methods', id: 'app.common.payment_methods' },
  { title: 'Shipping Information', href: '/shipping-information', id: 'app.common.shipping_information' },
  { title: 'FAQ', href: '/faq', id: 'app.common.faq' },
  { title: 'Shipping Policy', href: '/shipping-policy', id: 'app.common.shipping_policy' },
  { title: 'Refund Policy', href: '/refund-policy', id: 'app.common.refund_policy' },
  { title: 'Return Policy', href: '/return-policy', id: 'app.common.return_policy' },
  { title: 'Privacy Policy', href: '/privacy-policy', id: 'app.common.privacy_policy' },
];
