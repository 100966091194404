/**
 *
 * @description Autocomplete.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 25 October 2021
 *
 */

import Link from 'next/link';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { ProductT } from '~/@customTypes/product.type';
import useLoading from '~/v2/hooks/useLoading';
import { fetchProducts } from './Product.service';

let timeout: ReturnType<typeof setTimeout>;

export type ItemT = {
  label: string;
  value: string;
};
type Props = {
  label?: string | undefined;
  id?: string | undefined;
  placeholder?: string | undefined;
  locale?: string | undefined;
};
export function Autocomplete({ id, label, placeholder, locale }: Props): JSX.Element {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState('');
  const [data, setData] = useState<ProductT[]>([]);
  const wrapperRef = useRef(null);

  const { onStartLoading, onStopLoading } = useLoading();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    function handleClickOutside(event) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (id && event.target?.id !== `autocomplete-${id}`) {
          setOpen(false);
        }

        if (!id && event.target?.id !== 'eggtee-autocomplete') {
          setOpen(false);
        }
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setInput(value);

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(async () => {
      onStartLoading();
      const result = await fetchProducts({ locale: 'en', name: value, limit: 20 });

      onStopLoading();
      if (result && result.data) {
        setData(result.data);
      }
    }, 1000);
  };

  return (
    <div className='relative w-full sm:w-96'>
      {label && (
        <label htmlFor={id ? `autocomplete-${id}` : 'eggtee-autocomplete'} className={'block text-sm font-medium'}>
          {label}
        </label>
      )}

      <div className='mt-1'>
        <input
          type='text'
          id={id ? `autocomplete-${id}` : 'eggtee-autocomplete'}
          className={
            'focus:ring-orange-400 focus:border-orange-400 flex-grow block w-full min-w-0 rounded sm:text-sm border-gray-300'
          }
          style={{ height: 42 }}
          onChange={onChange}
          placeholder={placeholder}
          value={input}
          onFocus={() => setOpen(true)}
          autoComplete='none'
        />
      </div>

      <div id='autocomplete-dropdown' ref={wrapperRef} className='absolute w-full z-10'>
        {open && (
          <div className='bg-white border border-gray-100 mt-2 rounded max-h-52 overflow-y-auto px-4 divide-x-2'>
            {data.length === 0 && (
              <div className='p-2'>
                <p className='py-2 text-sm text-gray-400'>No data available</p>

                <Link href='/packages'>
                  <a className='text-sm'>View All Products</a>
                </Link>
              </div>
            )}
            {input &&
              data.map((item: ProductT) => (
                <Link href={`/product/${item.url_key}/${item.id}`} key={item.id}>
                  <a className='text-sm p-2 hover:bg-gray-100 cursor-pointer'>
                    <span className='block'>{item.name}</span>
                    <span className='block text-xs'>{item.formated_price}</span>
                  </a>
                </Link>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}
